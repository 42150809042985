import { SharedAssetLinks } from '../components/SharedAsset/SharedAssetLinks';

function SharedMediaLinksRoute() {
  return <SharedAssetLinks />;
}

export const Component = SharedMediaLinksRoute;

export function clientLoader() {
  return null;
}
