import { Link, useNavigate, useParams } from '@remix-run/react';
import useSWR from 'swr';

import {
  type DtoBlock,
  type DtoGamePack,
  EnumsRefObjectType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { fromDTOGamePack } from '../../utils/api-dto';
import { GamePackCoverImage } from '../GamePackCover/GamePackCoverPres';
import { InfoIcon } from '../icons/InfoIcon';
import { Loading } from '../Loading';
import { BlockDocCard } from '../RoutedBlock';

function BlockLink(props: { block: DtoBlock }) {
  const { block } = props;
  const navigate = useNavigate();

  return (
    <BlockDocCard
      key={block.id}
      doc={{ block }}
      onClick={(doc) => navigate(`/admin/blocks/${doc.block.id}`)}
      menu={
        <div className='flex items-center justify-center gap-1'>
          {block.brandId ? (
            <button
              type='button'
              className='btn-warning w-6 h-6 rounded flex justify-center items-center'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/admin/brands/${block.brandId}`);
              }}
            >
              <InfoIcon />
            </button>
          ) : (
            <div className='w-6 h-6' />
          )}
        </div>
      }
    />
  );
}

function GamePackLink(props: { pack: DtoGamePack }) {
  const { pack } = props;

  return (
    <Link
      className={`flex items-center hover:bg-lp-gray-002 rounded-xl p-2 gap-4`}
      to={`/admin/gamepacks/edit/${props.pack.id}`}
    >
      <GamePackCoverImage
        pack={fromDTOGamePack(pack)}
        className='w-22.5 h-12.5'
        alt={''}
      />
      <div className='flex flex-col max-w-200'>
        <div className='text-base font-bold'>{pack.name}</div>
        <div className='text-sms line-clamp-2'>{pack.description}</div>
      </div>
    </Link>
  );
}

export function SharedAssetLinks() {
  const { id } = useParams<'id'>();
  const { data, isLoading } = useSWR(
    id ? `/media/shared/${id}/links` : null,
    async () => {
      if (!id) return null;
      const resp = await apiService.media.getSharedAssetLinks(id);
      return resp.data;
    }
  );

  if (isLoading) return <Loading />;

  return (
    <div className='w-full'>
      {data?.links.map((link) => {
        switch (link.objectType) {
          case EnumsRefObjectType.RefObjectTypeBlock:
            const block = data.blocks.find((b) => b.id === link.objectId);
            if (!block) return null;
            return <BlockLink key={block.id} block={block} />;
          case EnumsRefObjectType.RefObjectTypePrimeGamePack:
          case EnumsRefObjectType.RefObjectTypeGamePack:
            const pack = data.gamePacks.find((p) => p.id === link.objectId);
            if (!pack) return null;
            return <GamePackLink key={pack.id} pack={pack} />;
          default:
            return null;
        }
      })}
    </div>
  );
}
